import React, { useState, useEffect } from 'react';
import './Header.css';
import Assets from '../../Assets/Assets';

function Header() {
    const [userData, setUserData] = useState({ userName: 'Guest', email: '', phone: '' });
    const [error, setError] = useState(null);
    const token = sessionStorage.getItem('Token')
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append(
            "authtoken",
            token
        );

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch("https://api.theleadsense.com/api/v1/getusers/details", requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                const userDetails = result?.data?.users_details[0];
                if (userDetails) {
                    setUserData({
                        userName: userDetails.user_name || 'Guest',
                        email: userDetails.email || '',
                        phone: userDetails.phone || '',
                    });
                    sessionStorage.setItem('user_name', userDetails?.user_name)
                }
            })
            .catch((error) => {
                console.error(error);
                setError('Failed to fetch user details.');
            });
    }, []);

    return (
        <header className="header">
            <div className="header-content">
                <div className="header-logo">
                    <img src={Assets?.Logo} alt="Logo" className="logo" />
                </div>
                <div className="header-user">
                    {error ? (
                        <span className="user-name error">{error}</span>
                    ) : (
                        <>
                            <span className="user-name">{userData.userName}</span>
                            <img
                                src={Assets?.Login_b}
                                alt="Profile"
                                className="profile-photo"
                            />
                        </>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;
