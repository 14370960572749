import React, { useState, useEffect } from "react";
import { Line, Doughnut } from "react-chartjs-2";
import moment from "moment";
import { useQuery } from "react-query";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from "chart.js";

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);

// Function to fetch leads data
const fetchLeadsDetails = async () => {
    const token = sessionStorage.getItem("Token");
    if (!token) {
        throw new Error("No authentication token found!");
    }

    const myHeaders = new Headers();
    myHeaders.append("authtoken", token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    const response = await fetch(
        "https://api.theleadsense.com/api/v1/lead/details",
        requestOptions
    );

    const result = await response.json();
    if (result?.data?.leads) {
        // Filter leads for the last 7 weeks
        const sevenWeeksAgo = moment().subtract(7, "weeks");
        return result.data.leads.filter((lead) =>
            lead.cdate
                ? moment(lead.cdate, "DD-MM-YYYY HH:mm:ss").isSameOrAfter(sevenWeeksAgo)
                : false
        );
    }

    throw new Error("No leads data found in API response.");
};

function Analytics() {
    const [search, setSearch] = useState("");
    const [selectedStage, setSelectedStage] = useState("");
    const [startDate, setStartDate] = useState(""); // State for start date
    const [endDate, setEndDate] = useState(""); // State for end date

    // Fetch leads data using react-query's useQuery
    const { data: leadsDetails = [], isLoading, isError, error } = useQuery(
        "leadsDetails",
        fetchLeadsDetails
    );

    // Apply filters to leads data
    const applyFilters = (searchTerm, stage, start, end) => {
        let filtered = [...leadsDetails];

        if (searchTerm) {
            filtered = filtered.filter(
                (lead) =>
                    lead.name?.toLowerCase().includes(searchTerm) ||
                    lead.email?.toLowerCase().includes(searchTerm) ||
                    lead.phone?.toLowerCase().includes(searchTerm) ||
                    lead.course?.toLowerCase().includes(searchTerm)
            );
        }

        if (stage) {
            filtered = filtered.filter((lead) => String(lead.stage) === stage);
        }

        if (start) {
            filtered = filtered.filter((lead) =>
                lead.cdate
                    ? moment(lead.cdate, "DD-MM-YYYY HH:mm:ss").isSameOrAfter(
                        moment(start, "YYYY-MM-DD")
                    )
                    : false
            );
        }

        if (end) {
            filtered = filtered.filter((lead) =>
                lead.cdate
                    ? moment(lead.cdate, "DD-MM-YYYY HH:mm:ss").isSameOrBefore(
                        moment(end, "YYYY-MM-DD")
                    )
                    : false
            );
        }

        return filtered;
    };

    // Filter leads based on the current state
    const filteredLeads = applyFilters(search, selectedStage, startDate, endDate);

    // Calculate stage counts
    const calculateStageCounts = (leads) => {
        const stageCounts = {
            "0": 0, // Open Lead
            "1": 0, // RNR
            "2": 0, // Progress
            "5": 0, // Prospect
            "4": 0, // Good Lead
            "3": 0, // Rejected
            "6": 0, // Call Back
        };

        leads.forEach((lead) => {
            if (lead.stage !== undefined) {
                stageCounts[lead.stage] += 1;
            }
        });

        return stageCounts;
    };

    // Get stage counts for filtered leads
    const stageCounts = calculateStageCounts(filteredLeads);

    // Prepare data for the Donut Chart
    const donutChartData = {
        labels: [
            "Open Leads",
            "RNR",
            "Progress",
            "Prospects",
            "Good Leads",
            "Rejected Leads",
            "Call Back",
        ],
        datasets: [
            {
                label: "Leads by Stage",
                data: [
                    stageCounts["0"], // Open Lead
                    stageCounts["1"], // RNR
                    stageCounts["2"], // Progress
                    stageCounts["5"], // Prospect
                    stageCounts["4"], // Good Lead
                    stageCounts["3"], // Rejected
                    stageCounts["6"], // Call Back
                ],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#4BC0C0",
                    "#9966FF",
                    "#FF9F40",
                    "#FFB6C1",
                ],
                hoverBackgroundColor: [
                    "#FF3B4C",
                    "#1E76A6",
                    "#FF9D1D",
                    "#3A8F88",
                    "#7A48FF",
                    "#FF6D31",
                    "#FF8D9A",
                ],
            },
        ],
    };

    // Prepare data for the Line chart
    const prepareChartData = (leads) => {
        // Count leads per day
        const leadsPerDay = {};

        leads.forEach((lead) => {
            const date = moment(lead.cdate, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD");
            leadsPerDay[date] = (leadsPerDay[date] || 0) + 1;
        });

        // Prepare chart data
        const dates = Object.keys(leadsPerDay).sort();
        const counts = dates.map((date) => leadsPerDay[date]);

        return {
            labels: dates,
            datasets: [
                {
                    label: "Leads Created",
                    data: counts,
                    fill: false,
                    borderColor: "#007bff",
                    tension: 0.1,
                },
            ],
        };
    };

    return (
        <div className="reports">
            <div className="row">
                <div className="col-lg-12">
                    <h3>Leads - Chart View</h3>
                </div>
                <div className="col-lg-3 mb-3">
                    <label>Search</label>
                    <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value.toLowerCase())}
                        placeholder="Search by name, email, or phone"
                        className="form-control mb-2"
                    />
                </div>
                <div className="col-lg-3 mb-3">
                    <label>Stage</label>
                    <select
                        value={selectedStage}
                        onChange={(e) => setSelectedStage(e.target.value)}
                        className="form-control"
                    >
                        <option value="">All Stages</option>
                        <option value="0">Open Lead</option>
                        <option value="1">RNR</option>
                        <option value="2">Progress</option>
                        <option value="5">Prospect</option>
                        <option value="4">Good Lead</option>
                        <option value="3">Rejected leads</option>
                        <option value="6">Call Back</option>
                    </select>
                </div>
                <div className="col-lg-3 mb-3">
                    <label>Start Date</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="form-control"
                    />
                </div>
                <div className="col-lg-3 mb-3">
                    <label>End Date</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="form-control"
                    />
                </div>
                <div className="col-lg-12">
                    {isLoading ? (
                        <div>Loading leads...</div>
                    ) : isError ? (
                        <div className="text-danger">{error.message}</div>
                    ) : (
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="donut-chart">
                                    <h4>Lead Stages Distribution</h4>
                                    <Doughnut data={donutChartData} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <Line data={prepareChartData(filteredLeads)} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Analytics;
