import React, { useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useQuery } from "react-query";

// Function to fetch leads data
const fetchLeadsDetails = async () => {
    const token = sessionStorage.getItem("Token");
    if (!token) {
        throw new Error("No authentication token found!");
    }

    const myHeaders = new Headers();
    myHeaders.append("authtoken", token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    const response = await fetch(
        "https://api.theleadsense.com/api/v1/lead/details",
        requestOptions
    );

    const result = await response.json();
    if (result?.data?.leads) {
        // Filter leads for the last 7 weeks
        const sevenWeeksAgo = moment().subtract(7, "weeks");
        return result.data.leads.filter((lead) =>
            lead.cdate
                ? moment(lead.cdate, "DD-MM-YYYY HH:mm:ss").isSameOrAfter(sevenWeeksAgo)
                : false
        );
    }

    throw new Error("No leads data found in API response.");
};

function Leads() {
    const [search, setSearch] = useState("");
    const [selectedStage, setSelectedStage] = useState("");
    const [startDate, setStartDate] = useState(""); // State for start date
    const [endDate, setEndDate] = useState(""); // State for end date

    // Fetch leads data using react-query's useQuery
    const { data: leadsDetails = [], isLoading, isError, error } = useQuery(
        "leadsDetails", 
        fetchLeadsDetails
    );

    // Handle Search Input
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearch(value);
    };

    // Handle Stage Filtering
    const handleStageFilter = (e) => {
        const value = e.target.value;
        setSelectedStage(value);
    };

    // Handle Date Filtering
    const handleStartDateChange = (e) => {
        const value = e.target.value;
        setStartDate(value);
    };

    const handleEndDateChange = (e) => {
        const value = e.target.value;
        setEndDate(value);
    };

    // Apply All Filters
    const applyFilters = (searchTerm, stage, start, end) => {
        let filtered = [...leadsDetails];

        if (searchTerm) {
            filtered = filtered.filter(
                (lead) =>
                    lead.name?.toLowerCase().includes(searchTerm) ||
                    lead.email?.toLowerCase().includes(searchTerm) ||
                    lead.phone?.toLowerCase().includes(searchTerm) ||
                    lead.course?.toLowerCase().includes(searchTerm)
            );
        }

        if (stage) {
            filtered = filtered.filter((lead) => String(lead.stage) === stage);
        }

        if (start) {
            filtered = filtered.filter((lead) =>
                lead.cdate
                    ? moment(lead.cdate, "DD-MM-YYYY HH:mm:ss").isSameOrAfter(
                        moment(start, "YYYY-MM-DD")
                    )
                    : false
            );
        }

        if (end) {
            filtered = filtered.filter((lead) =>
                lead.cdate
                    ? moment(lead.cdate, "DD-MM-YYYY HH:mm:ss").isSameOrBefore(
                        moment(end, "YYYY-MM-DD")
                    )
                    : false
            );
        }

        return filtered;
    };

    // Filter leads based on the current state
    const filteredLeads = applyFilters(search, selectedStage, startDate, endDate);

    // Define Table Columns
    const columns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Lead ID",
            selector: (row) => row.id || "N/A",
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name || "N/A",
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email || "N/A",
            sortable: true,
        },
        {
            name: "Phone",
            selector: (row) => row.phone || "N/A",
            sortable: true,
        },
        {
            name: "Course",
            selector: (row) => row.course || "N/A",
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) =>
                row.status === "0" ? "Inactive" : "Active",
            sortable: true,
        },
        {
            name: "Stage",
            selector: (row) => {
                switch (row.stage) {
                    case "0":
                        return "Open Lead";
                    case "1":
                        return "RNR";
                    case "2":
                        return "Progress";
                    case "3":
                        return "Rejected";
                    case "4":
                        return "Good Lead";
                    case "5":
                        return "Prospect";
                    case "6":
                        return "Call Back";
                    default:
                        return "N/A";
                }
            },
            sortable: true,
        },
        {
            name: "Created Date",
            selector: (row) =>
                row.cdate
                    ? moment(row.cdate, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY")
                    : "N/A",
            sortable: true,
        },
    ];

    return (
        <div className="reports">
            <div className="row">
                <div className="col-lg-12">
                    <h3>Leads Details</h3>
                </div>
                <div className="col-lg-3 mb-3">
                    <label>Search</label>
                    <input
                        type="text"
                        value={search}
                        onChange={handleSearch}
                        placeholder="Search by name, email, or phone"
                        className="form-control mb-2"
                    />
                </div>
                <div className="col-lg-3 mb-3">
                    <label>Stage</label>
                    <select
                        value={selectedStage}
                        onChange={handleStageFilter}
                        className="form-control"
                    >
                        <option value="">All Stages</option>
                        <option value="0">Open Lead</option>
                        <option value="1">RNR</option>
                        <option value="2">Progress</option>
                        <option value="5">Prospect</option>
                        <option value="4">Good Lead</option>
                        <option value="3">Rejected leads</option>
                        <option value="6">Call Back</option>
                    </select>
                </div>
                <div className="col-lg-3 mb-3">
                    <label>Start Date</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        className="form-control"
                    />
                </div>
                <div className="col-lg-3 mb-3">
                    <label>End Date</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        className="form-control"
                    />
                </div>
                <div className="col-lg-12">
                    {isLoading ? (
                        <div>Loading leads...</div>
                    ) : isError ? (
                        <div className="text-danger">{error.message}</div>
                    ) : (
                        <DataTable
                            columns={columns}
                            data={filteredLeads}
                            pagination
                            paginationPerPage={10} // Default rows per page
                            paginationRowsPerPageOptions={[5, 10, 15, 20]} // Options for rows per page
                            responsive
                            highlightOnHover
                            striped
                            noDataComponent={<div>No leads available</div>}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Leads;
