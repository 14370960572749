import React, { useState } from "react";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LeadUpload() {
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    // Handle file input change
    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setFile(file);
            // toast.success(`File "${file.name}" is uploaded successfully.`);
        } else {
            toast.error("Please upload a valid Excel file.");
        }
    };

    // Handle file submission
    const handleFileSubmit = () => {
        const authToken = sessionStorage.getItem("Token"); // Get the authToken from sessionStorage

        if (authToken) {
            if (file) {
                setIsUploading(true);
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "binary" });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet);

                    toast.info("Processing your data...");

                    // Track the results
                    let successCount = 0;
                    let errorCount = 0;

                    const promises = jsonData.map((row) => {
                        const raw = JSON.stringify({
                            action: "insertLead",
                            name: row.Name,
                            email: row.Email,
                            phone: row.Phone,
                            course: row.Course,
                        });

                        const myHeaders = new Headers();
                        myHeaders.append("authToken", authToken); // Use the authToken from sessionStorage
                        myHeaders.append("Content-Type", "application/json");

                        const requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: raw,
                            redirect: "follow",
                        };

                        return fetch("https://iop.liba.edu/api/v1/addLead/", requestOptions)
                            .then((response) => response.json())
                            .then((result) => {
                                if (result.Status === "200") {
                                    successCount++;
                                    console.log(`Lead ID ${result.l_id} added successfully.`);
                                } else {
                                    errorCount++;
                                    console.error(`Failed to add lead: ${row.Name}`);
                                }
                            })
                            .catch(() => {
                                errorCount++;
                            });
                    });

                    Promise.all(promises)
                        .then(() => {
                            toast.success(
                                `Data upload complete! ${successCount} leads added successfully, ${errorCount} failed.`
                            );
                            setFile(null); // Reset the file after processing
                        })
                        .catch(() => {
                            toast.error("An error occurred while processing the data.");
                        })
                        .finally(() => {
                            setIsUploading(false);
                        });
                };
                reader.readAsBinaryString(file);
            } else {
                toast.error("Please upload a file.");
            }
        } else {
            toast.error("No authToken found. Please log in again.");
        }
    };

    // Download Sample Excel
    const handleSampleDownload = () => {
        const sampleData = [
            { Name: "John Doe", Email: "john@example.com", Phone: "1234567890", Course: "Math" },
            { Name: "Jane Smith", Email: "jane@example.com", Phone: "9876543210", Course: "Science" },
        ];

        const worksheet = XLSX.utils.json_to_sheet(sampleData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sample");

        // Save the file
        XLSX.writeFile(workbook, "Sample_Leads.xlsx");
    };

    // Dropzone settings
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleDrop,
        accept: ".xlsx,.xls",
    });

    return (
        <div className="row">
            <div className="col-lg-6 mt-3 mb-3">
                <h1>Upload Lead</h1>
            </div>
            <div className="col-lg-6 mt-3 mb-3">
                <button
                    className="btn btn-primary w-50"
                    style={{ float: "right" }}
                    onClick={handleSampleDownload}
                >
                    Download Sample Excel
                </button>
            </div>
            <div className="col-lg-12">
                <div className="card" style={{ padding: "30px" }}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                {...getRootProps()}
                                style={{
                                    border: "2px dashed #ccc",
                                    padding: "20px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                }}
                            >
                                <input {...getInputProps()} />
                                <p>
                                    Drag & Drop your Excel file here, or click to select
                                </p>
                                {file && <p style={{ marginTop: "10px", color: "green" }}>{file.name}</p>}
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <button
                                    className="btn btn-primary"
                                    onClick={handleFileSubmit}
                                    disabled={isUploading}
                                    style={{ marginTop: "35px", marginBottom: "20px",width:"30%" }}
                                >
                                    {isUploading ? "Uploading..." : "Upload and Send to API"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Toast container to display the messages */}
            <ToastContainer />
        </div>
    );
}

export default LeadUpload;
