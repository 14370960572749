import React from 'react';
import Assets from '../../Assets/Assets';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import Appcontent from './Appcontent';


function DefaultLayout() {
    const menuItems = [
        { name: 'Dashboard', link: '/', icon: '📊' },  // Bar Chart for analytics overview
        { name: 'Lead Master', link: '/leads', icon: '📋' },  // Clipboard for data management
        { name: 'Reports', link: '/reports', icon: '📈' },  // Upward trend chart for reports
    
        { name: 'Logout', link: '/logout', icon: '🚪' },  // Door for exiting
   
    ];
    



    return (
        <div className="dashboard-layout">
            <Sidebar menuItems={menuItems} logo={Assets?.Logo} />
            <div className="main-content">
                <Header
                    logo={Assets?.Logo}
                    userName="John Doe"
                    profilePhoto={Assets?.Login_b}
                />
                <div className="contents" style={{ textAlign: "start", paddingLeft: "19%", paddingTop: "6%" }}>
                    <Appcontent />
                </div>
            </div>
        </div>
    );
}

export default DefaultLayout;
